<template>

  <div class="row" ref="roleForm">

    <div class="col-xs-8 offset-xs-2 col-md-10 offset-md-1 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <ValidationProvider
              vid="name"
              rules="required"
              name="The name"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        label="Name"
                        name="name"
                        fou
                        v-model="formData.name">
              </fg-input>
            </ValidationProvider>

            <div class="row table-full-width">
             <div class="row">
               <div class="col-md-4">
                 <el-input type="search"
                           class="mb-3"
                           style="width: 200px"
                           placeholder="Search records"
                           v-model="searchQuery"
                           aria-controls="datatables"/>
                 <el-select
                         class="select-default mb-3 mx-3"
                         style="width: 75px"
                         v-model="pagination.perPage"
                         placeholder="Per page">
                   <el-option
                           class="select-default"
                           v-for="item in pagination.perPageOptions"
                           :key="item"
                           :label="item"
                           :value="item">
                   </el-option>
                 </el-select>
               </div>
               <div class="col-md-8">
                 <!--    filters    -->
                 <div class="row col-12 mb-2">
                   <div class="col-md-8 col-sm-4">
                     <fg-select
                             size="large"
                             filterable
                             clearable
                             placeholder="Select Group"
                             :input-classes="'select-default'"
                             :list="filterBuilders.groups"
                             :listItemLabel="'name'"
                             :listItemValue="'id'"
                             v-model="filters.group_id">
                     </fg-select>
                   </div>
                 </div>
                 <!--  end  filters    -->
               </div>
             </div>



              <div class="col-sm-12">
                <el-table class="table"
                          :data="queriedData">
                  <el-table-column v-for="column in tableColumns"
                                   :key="column.label"
                                   :min-width="column.minWidth"
                                   :align="column.align"
                                   :sortable="column.sortable"
                                   :prop="column.value"
                                   :label="column.label"
                  >

                  </el-table-column>

                  <el-table-column
                    :min-width="120"
                    fixed="right"
                    align="center"
                    label="Active">
                    <template slot-scope="props">

                      <l-switch
                        v-model="props.row.is_active">
                        <i class="fa fa-check" slot="on"></i>
                        <i class="fa fa-times" slot="off"></i>
                      </l-switch>

                    </template>
                  </el-table-column>

                </el-table>
                <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                  <div class="">
                    <p class="card-category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
                  </div>
                  <l-pagination class="pagination-no-border"
                                v-model="pagination.currentPage"
                                :per-page="pagination.perPage"
                                :total="pagination.total">
                  </l-pagination>
                </div>
              </div>
            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/roles/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option, Table, TableColumn} from 'element-ui';
import {Switch as LSwitch} from '@/components'
import {Pagination as LPagination} from 'src/components/index'
import FgSelect from "@/components/Inputs/formGroupSelect";
import Fuse from 'fuse.js'


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LSwitch,
    LPagination,
    FgSelect,
  },
  computed: {
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== '') {
        result = result.filter( ({ title }) => title.toLowerCase().includes(this.searchQuery));
      }
      if(this.filters.group_id !== "" && this.filters.group_id !== null){
          result = result.filter( ({ groups }) => groups.find(item => item.id === this.filters.group_id));
      }
      this.pagination.total = result.length
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    }
  },
  data() {
    return {
      tableData:[],
      fuseSearch: null,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      propsToSearch: ['title'],
      searchQuery: '',
      tableColumns: [
        {label: 'Title', value: 'title', minWidth: '200', align: 'center'},
      ],
      editMode: false,
      loader: {},
      id: undefined,
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      formData: {
        name: "",
        permissions: [],
      },
      permissions: [],
      filterBuilders: {
          groups: []
      },

      filters: {
          group_id: null
      },
    };
  },
  mounted() {
    let data = {
      'lang': this.lang
    }
    this.loader = this.$loading.show({
      container: this.$refs.roleForm
    });
    this.axios.post("roles/builder", data).then((response) => {
      this.permissions = response.data.permissions;
      this.filterBuilders.groups = response.data.groups;
      this.id = this.$route.params['id'];
      this.tableData = this.permissions.map((item) => {return {...item,'is_active':false}});
      this.loader.hide();

      if (this.id !== undefined) {

        this.editMode = true;
        this.formTitle = "Edit Role";
        this.getRole();

      } else {
        this.editMode = false;
        this.formTitle = "Add Role";
        this.loader.hide();
      }

    }).catch((error) => {
      console.error(error);
    });
  },


  methods: {


    getRole() {
      this.axios.get("roles/get/" + this.id).then((response) => {
        this.formData.name = response.data.name;
        this.formData.permissions = response.data.permissions.map(p => {
          return p.id
        });
        this.tableData.map(item => {
          if(this.formData.permissions.includes(item.id)){
            item.is_active = true;
          }else{
            item.is_active = false;
          }
        });
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Role Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      this.formData.permissions = this.tableData.filter(element => element.is_active === true);
      this.formData.permissions = this.formData.permissions.map(p => {
        return p.id
      });

      if (this.editMode === true) {

        request = this.axios.put("roles/update/" + this.id, this.formData);
        successMessage = "Role Updated Successfully";
      } else {
        request = this.axios.post("roles/create", this.formData);
        successMessage = "Role Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/roles/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },

  }
}
</script>

<style>
</style>
